import "./Styles/Theme.scss";
import Accueil from "./Components/Pages/Accueil";
function App() {
  return (
    <div className="App">
      <Accueil />
    </div>
  );
}

export default App;
